import httpClient from "./http.service";
import {Message} from "@/models/chat/message";



const chatService = {

    // async get(day: number | string | undefined, status: string | undefined, userId: string | null = localStorage.getItem("user_id")) {
    //     const requestedDay = (day !== undefined && day !== null && day >0 )? day : (new Date()).getSeconds()
    //     const requestedStatus = (status !== undefined && status !== '' )? status : "All"
    //     const requestedUser = (userId !== undefined && userId !== null && userId !== '' )? userId : localStorage.getItem("user_id")
    //     if (userId === undefined || userId !== null || userId !== '' ) {
    //         console.log('helpdesk?day=' + requestedDay + '&type='+requestedStatus)
    //         return httpClient.get('helpdesk?day=' + requestedDay + '&type=' + requestedStatus)
    //     }
    //     else {
    //         console.log('helpdesk?day=' + requestedDay + '&type='+requestedStatus+'&forUser?='+requestedUser)
    //         return httpClient.get('helpdesk?day=' + requestedDay + '&type='+requestedStatus+'&forUser?='+requestedUser)
    //     }
    //
    // },
    // async getWithRequestForm(request: RequestTickets) {
    //     const dayInSeconds = (request.day>0) ? request.day : ((new Date()).getTime()/1000).toFixed(0);
    //     const requestedStatus = (request.status !== undefined && request.status !== '' )? request.status : "All"
    //     const requestedUser = (request.user !== undefined && request.user !== null && request.user !== '' )? request.user : localStorage.getItem("user_id")
    //     if (request.user === undefined || request.user !== null || request.user !== '' )
    //         return httpClient.get('helpdesk?day=' + dayInSeconds + '&type='+requestedStatus)
    //     return httpClient.get('helpdesk?day=' + dayInSeconds + '&type='+requestedStatus+'&forUser?='+requestedUser +
    //         ((request.department !== undefined)? '&dept_id?='+request.department:'')
    //     )
    // },

    async fetchChats(userId: number) {
        return httpClient.get('chats?recipientId=' + userId + '$authorId=' + userId)
    },
    async fetchMessages(chatId: number) {
        return httpClient.get('chat?id=' + chatId)
    },
    async getMessage(id: number) {
        return httpClient.get('message?id=' + id)
    },
    async sendMessage(message: Message) {
        return httpClient.post('message', message)
    },
    async deleteMessage(messageId: number) {
        return httpClient.delete('message/?id='+ messageId)
    },
    async sendInviteMessage(params: { chat_id: string;   ids: [] }) {
        return httpClient.post('chat/invite?id=chat_id',{chatId: params.chat_id, ids: params.ids})
    },
    async acceptInviteMessage(params: { chat_id: string;   messageId: number }) {
        return httpClient.post('chat/join?id='+params.chat_id+'&messageId='+params.messageId)

    },
    async declineInviteMessage(params: { chat_id: string;   messageId: number }) {
        return httpClient.post('chat/invite?id'+params.chat_id+'&messageId='+params.messageId)
    },
    async leaveChat(params: { chat_id: string;   userId: number }) {
        return httpClient.post('chat/leave',{id: params.chat_id, removedUserId: params.userId})
    },
    async setAvatar(formData: FormData, id: number) {
        return httpClient.post('avatar?id='+ id, formData)
    },
    async sendPicture(formData: FormData) {
        return httpClient.post('message', formData)
    },
    async sendAudio(formData: FormData) {
        return httpClient.post('message', formData)
    },
    async delete(id: number) {
        return httpClient.delete('message?id='+id)
    },
    async update(formData: FormData) {
        return httpClient.put('message', formData)
    },
};

export default chatService;


/**
 //Messenger
 'GET messages' => 'message/index',
 'GET message' => 'message/view',
 'GET accept/ticket' => 'message/accept-ticket',
 'GET decline/ticket' => 'message/decline-ticket',
 'POST message' => 'message/create',
 'POST message/reply' => 'message/reply',
 'DELETE message' => 'message/delete',
 'PUT message' => 'message/update',
 'POST message/read' => 'message/read',
 'GET message/update' => 'synchro/message-hook',


 'POST confirm-rent'=>'rents/confirm-rent',
 'POST paid-rent'=>'rents/paid-rent',

 'GET ticket/rating' => 'helpdesk/ticket-rating',

 'GET chat'=>'chat/view',
 'GET chats'=>'chat/index',
 'DELETE chat'=>'chat/delete',
 'PUT chat'=>'chat/update',
 'POST chat'=>'chat/create',
 'POST chat/join'=>'chat/accept',
 'POST chat/decline'=>'chat/decline',
 'POST chat/leave'=>'chat/leave',
 'POST chat/read'=>'chat/read',
 'POST avatar'=>'chat/avatar',
 'GET avatar'=>'user/avatar',
 'POST chat/invite'=>'chat/invite',
 * */
