import httpClient from "./http.service";
import GuestUser from "@/models/guest-user";
import {useStore} from "@/store";
import {ActionTypes} from "@/store/actions";
import {MutationType} from "@/store/mutations";
import router from "@/router";

const store = useStore();
/**
 * Created by TheCodeholic on 3/7/2020.
 */
const authService = {
    currentUser: null,
    guestUser: GuestUser,
    isLoggedIn() {
        return !!localStorage.getItem('ACCESS_TOKEN')
    },
    getToken() {
        return localStorage.getItem('ACCESS_TOKEN')
        // return "2a1bd50e2df55e8f264bc76f6d65d253baeee2ba"
    },
    async login(formData: { password: string; clientId: string; clientSecret: string; grantType: string; username: string }) {
        try {
            const {status, data} = await httpClient.post('board-login', formData);
            //const {status, data} = await httpClient.post('"http://192.168.1.4/board-login', formData);
            if (status === 200) {
                localStorage.setItem('ACCESS_TOKEN', data.access_token);
                localStorage.setItem('ACCESS_TOKEN_EXPIRES', new Date() + data.expires_in);
                localStorage.setItem('REFRESH_TOKEN', data.refresh_token);
                localStorage.setItem('CONTACT_NAME', data.contact_name);
                localStorage.setItem('USER_ID', data.user_id);
                localStorage.setItem('ACCOUNT_ID', data.account_id);
                localStorage.setItem('USER_ROLES', data.user_roles);
                localStorage.setItem('AVATAR_LINK', data.avatar_link);
                store.commit(MutationType.setCredentials, data)
                store.dispatch(ActionTypes.setUserLoggedIn);
                return {success: true}
            }
            return { success: false,
                errors: "wrong credentials"}
        } catch (e) {
            console.log(e.response);
            return {
                success: false,
                errors: "wrong credentials"
            }
        }
    },
    async register(formData: Array<string>) {
        try {
            debugger
            const {status, data} = await httpClient.post('worker/register', formData);
            if (status === 200 || status === 201) {
                localStorage.setItem('ACCESS_TOKEN', data.access_token)
                localStorage.setItem('ACCESS_TOKEN_EXPIRES', new Date() + data.expires_in)
                localStorage.setItem('REFRESH_TOKEN', data.refresh_token)
                await store.dispatch(ActionTypes.getUser)
                store.dispatch(ActionTypes.setUserLoggedIn);
                return {  success: true, errors: "", token: true }
            }
            else if (status === 205) {
                return {  success: true , errors: "", token: false }
            }
            else return { success: false, errors: "wrong credentials"}
        } catch (e) {
            console.log(e.response);
            return {
                success: false,
                errors: "wrong credentials"
            }
        }
    },
    async checkUsername(username: string) {
        try {
            debugger
            const {status, data} = await httpClient.get('profile/username?username='+ username);
            if (status === 200 && data.success) {
                return {  success: true, errors: "", token: true }
            }
            else if (status === 500 || !data.success) {
                return {  success: false , errors: "", token: false }
            }
            else return { success: false, errors: data.message}
        } catch (e) {
            console.log(e.response);
            return {
                success: false,
                errors: "error occurred."
            }
        }
    },
    async checkEmail(email: string) {
        try {
            const {status, data} = await httpClient.get('profile/email?email='+ email);
            if (status === 200 && data.success) {
                return {  success: true, errors: "", token: true }
            }
            else if (status === 500 || !data.success) {
                return {  success: false , errors: "", token: false }
            }
            else return { success: false, errors: data.message}
        } catch (e) {
            console.log(e.response);
            return {
                success: false,
                errors: "error occurred."
            }
        }
    },
    async auth (formData: Array<string>) {
        try {

            const {status, data} = await httpClient.post('auth', formData);

            if (status === 200 || status === 201) {
                localStorage.setItem('ACCESS_TOKEN', data.access_token)
                localStorage.setItem('ACCESS_TOKEN_EXPIRES', new Date() + data.expires_in)
                localStorage.setItem('REFRESH_TOKEN', data.refresh_token)
                await store.dispatch(ActionTypes.getUser)
                store.dispatch(ActionTypes.setUserLoggedIn);
                return {
                    success: true
                }
            }
            return { success: false, errors: "wrong credentials"}
        } catch (e) {
            console.log(e.response);
            return {
                success: false, errors: "wrong credentials"
            }
        }
    },
    async sendRequest(formData: Array<string>){
        try {

            const {status, data} = await httpClient.post('password-email', formData);

            if (status === 200 || status === 201) {
                localStorage.setItem('ACCESS_TOKEN', data.access_token)
                localStorage.setItem('ACCESS_TOKEN_EXPIRES', new Date() + data.expires_in)
                localStorage.setItem('REFRESH_TOKEN', data.refresh_token)
                await store.dispatch(ActionTypes.getUser)
                store.dispatch(ActionTypes.setUserLoggedIn);
                return {
                    success: true
                }
            }
            return { success: false, errors: "wrong credentials"}
        }
        catch (e) {
            console.log(e.response);
            return {
                success: false, errors: "wrong credentials"
            }
        }
    },

    logout() {
        localStorage.removeItem('ACCESS_TOKEN');
        localStorage.removeItem('REFRESH_TOKEN');
        localStorage.removeItem('ACCESS_TOKEN_EXPIRES');
        localStorage.removeItem('USER_ID');
        localStorage.removeItem('ACCOUNT_ID');
        localStorage.removeItem('USER_ROLES');
        localStorage.removeItem('AVATAR_LINK');
        localStorage.removeItem('CONTACT_NAME');

        store.commit(MutationType.unSetCredentials, false);
        store.dispatch(ActionTypes.unSetUser);
    },
    async getUser() {

        try {
            if (!this.currentUser) {
                const {status, data} = await httpClient.get('user');
                if (status === 200) {
                    this.currentUser = data;
                    store.commit(MutationType.setCredentials, data)
                    localStorage.setItem('CONTACT_NAME', data.name)
                    localStorage.setItem('USER_ID', data.id)
                    localStorage.setItem('ACCOUNT_ID', data.account_id)
                    localStorage.setItem('USER_ROLES', data.roles)
                    localStorage.setItem('AVATAR_LINK', data.avatar_link)
                }
            }
        } catch (e) {
            return this.guestUser;
        }
        return this.currentUser;
    },
    async pingPong(formData: Array<string>) {
        try {
            if (!this.currentUser) {
                const {status, data} = await httpClient.post('hello',formData);
                if (status === 200) {

                    localStorage.setItem('HELLO', data.success)
                }
            }
        } catch (e) {
            return this.guestUser;
        }
        return this.currentUser;
    }
};

export default authService;
