import httpClient from "./http.service";

const locationService = {
    async tracks(userId: number, from: number|null, to: number|null) {
        return httpClient.get('helpdesk/user-track?id='+userId)
    },
    async location(id: number) {
        return httpClient.get('location?id='+id)
    },
    async locations(id: number) {
        return httpClient.get('locations?id='+id)
    },
    async route(id: number) {
        return httpClient.get('route?id='+id)
    },
    async currentPosition(id: number) {
        return httpClient.get('position?id='+id)
    },
};

export default locationService;
