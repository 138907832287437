import httpClient from "./http.service";

const registersService = {

    async departments() {
        return httpClient.get('helpdesk/departments')
    },
    async statuses() {
        return httpClient.get('helpdesk/statuses')
    },
    async priorities() {
        return httpClient.get('ticket-priorities')
    },
    async apartments(officeId: number|null) {
        if (officeId!==null && officeId!==undefined)
            return httpClient.get('helpdesk/objects?office'+officeId)
        return httpClient.get('helpdesk/objects')
    },
    async topics() {
        return httpClient.get('helpdesk/topics')
    },
    async teams(id: number) { //for department id
        return httpClient.get('helpdesk/teams?id=' + id)
    },
    async allTeams() {
        return httpClient.get('helpdesk/teams')
    },
    async teamsWorkers(teamId: number) {
        return httpClient.get('helpdesk/users-list?id=' + teamId)
    },


};

export default registersService;
