import {State} from "./state";
import {MutationTree} from "vuex";
import Ticket from "@/models/ticket";
import Department from "../models/department";
import Team from "../models/team"
import User from "@/models/user";
import WorkerLoad from "@/models/worker-load";
import {CoWorker} from "@/models/co-worker";
// import {ActionTypes} from "@/store/actions";
import Priority from "@/models/priority";
import Location from "@/models/location/location";
import Topic from "@/models/topic";
import Apartment from "@/models/apartment";
import {Message} from "@/models/chat/message";
import {ActionTypes} from "@/store/actions";
import chatService from "@/services/chatService";
import {Chat} from "@/models/chat/chat";
import {Owner} from "@/models/owner";
import {Worker} from "@/models/worker";
import {Credentials} from "@/models/credentials/credentials";
import {MainFilter} from "@/models/filter/main-filter";
import {createDecorator} from "vue-class-component";


export enum MutationType {
    addTicket = "ADD_TICKET",
    fetchTickets = "FETCH_TICKET",
    startTicket = "START_TICKET",
    deleteTicket = "DELETE_TICKET",
    setRangeMainFilter = "SET_RANGE_MAIN_FILTER",
    setDatesMainFilter = "SET_DATES_MAIN_FILTER",
    pauseTicket = "PAUSE_TICKET",
    finishTicket = "FINISH_TICKET",
    closeTicket = "CLOSE_TICKET",
    reopenTicket = "REOPEN_TICKET",
    saveTicket = "SAVE_TICKET",
    setLoading = 'SET_LOADING',
    moveUp = 'MOVE_UP',
    moveDown = 'MOVE_DOWN',
    getDepartments = 'GET_DEPARTMENTS',
    getTeams= 'GET_TEAMS',
    getUser="GET_USER",
    unSetUser="UNSET_USER",
    setUserLoggedIn="SET_USER_LOGGED_IN",
    setCurrentDepartment="SET_CURRENT_DEPARTMENT",
    setApartmentNameFilter="SET_CURRENT_APARTMENT_NAME",
    setCurrentPage="SET_CURRENT_PAGE",
    setCurrentOffice="SET_CURRENT_OFFICE",
    getStatuses="GET_STATUSES",
    setCurrentStatus="SET_CURRENT_STATUS",
    setCurrentDay="SET_CURRENT_DAY",
    fetchWorkersLoad="FETCH_WORKERS",
    fetchResources="FETCH_RESOURCES",
    fetchPriorities="GET_PRIORITY",
    fetchApartments="GET_APARTMENT",
    fetchTopics="GET_TOPICS",
    fetchTeams="FETCH_TEAMS",
    addObject= "AddNewObject",
    updateObject= "UpdateObject",
    changeModal= "CHANGE_MODAL",
    assignWorker= "ASSIGN_WORKER",
    removeWorker= "Remove_WORKER",
    assignMajor= "ASSIGN_MAJOR",
    removeMajor= "Remove_MAJOR",
    updateTicket= "TICKET_UPDATE",
    updateLocation= "LOCATION_UPDATE",
    viewedTicket= "TICKET_VIEWED",
    setConnection= "WEB_SOCKET_CONNECTION",
    changeConnectionStatus= "CHANGE_WEB_SOCKET_CONNECTION",
    fetchChats = "FETCH_CHATS",
    fetchMessages = "FETCH_MESSAGES",
    getMessage = "GET_MESSAGE",
    sendMessage = "SEND_MESSAGE",
    deleteMessage = "DELETE_MESSAGE_",
    setMainfilter = "SET_MAIN_FILTER",

    fetchOwners = "FETCH_OWNERS",
    getOwner = "GET_OWNER",
    createOwner = "CREATE_OWNER",
    updateOwner = "UPDATE_OWNER",
    deleteOwner = "DELETE_OWNER",

    getWorkers = "GET_WORKERS",
    getWorker = "GET_WORKER",
    createWorker = "CREATE_WORKER",
    updateWorker = "UPDATE_WORKER",
    deleteWorker = "DELETE_WORKER",
    setFilterResources ="SET_FILTER_RESOURCES",
    setCredentials = "SET_CREDENTIALS",
    unSetCredentials = "UNSET_CREDENTIALS",

    setActiveChat = "SET_ACTIVE_CHAT",
    setActiveChatId = "SET_ACTIVE_CHAT_ID",
    pushMessageToActiveChat = "PUSH_MESSAGE_TO_ACTIVE_CHAT",
    viewedChat = "CHAT_VIEWED",
    addLog = "ADD_LOG",
    addMessageLog = "ADD_MESSAGE_LOG",
    resetUnreadLogsCount = "RESET_LOGS",
    resetUnreadMessageLogsCount = "RESET_MESSAGE_LOGS",
}

export type Mutations = {
    [MutationType.setCredentials](state: State, credentials: Credentials): void;
    [MutationType.unSetCredentials](state: State, value: boolean): void;
    [MutationType.addTicket](state: State, ticket: Ticket): void;
    [MutationType.addObject](state: State, apartment: Apartment): void;
    [MutationType.updateObject](state: State, apartment: Apartment): void;
    [MutationType.setMainfilter](state: State, value: MainFilter): void;
    [MutationType.fetchTickets](state: State, tickets: Ticket[]): void;
    [MutationType.fetchWorkersLoad](state: State, workers: WorkerLoad[]): void;
    [MutationType.fetchResources](state: State, workers: CoWorker[]): void;
    [MutationType.getDepartments](state: State, departments: Department[]): void;
    [MutationType.getStatuses](state: State, statuses: Array<Map<number, string>>): void;
    [MutationType.setRangeMainFilter](state: State, range: string): void;
    [MutationType.setDatesMainFilter](state: State, dates: number[]): void;
    [MutationType.getTeams](state: State, teams: Team[]): void;
    [MutationType.fetchTeams](state: State, teams: Team[]): void;
    [MutationType.startTicket](state: State, id: number): void;
    [MutationType.deleteTicket](state: State, id: number): void;
    [MutationType.pauseTicket](state: State, id: number): void;
    [MutationType.finishTicket](state: State, id: number): void;
    [MutationType.closeTicket](state: State, id: number): void;
    [MutationType.reopenTicket](state: State, id: number): void;
    [MutationType.saveTicket](state: State, id: number): void;
    [MutationType.setLoading](state: State, value: boolean): void;
    [MutationType.moveUp](state: State, id: number): void;
    [MutationType.moveDown](state: State, id: number): void;
    [MutationType.getUser](state: State, user: User|null): void;
    [MutationType.unSetUser](state: State, value: boolean): void;
    [MutationType.setUserLoggedIn](state: State, value: boolean): void;
    [MutationType.setCurrentDepartment](state: State, value: []): void;
    [MutationType.setApartmentNameFilter](state: State, value: string): void;
    // [MutationType.setActiveChat](state: State, value: Chat): void;
    [MutationType.setActiveChatId](state: State, value: number): void;
    [MutationType.setActiveChat](state: State, value: Chat): void;
    [MutationType.setCurrentPage](state: State, page: number): void;
    [MutationType.setCurrentOffice](state: State, value: []): void;
    [MutationType.setFilterResources](state: State, value: string): void;
    [MutationType.setCurrentDay](state: State, value: Date): void;
    [MutationType.setCurrentStatus](state: State, value: []): void;
    [MutationType.fetchPriorities](state: State, priorities: Priority[]): void;
    [MutationType.fetchApartments](state: State, apartments: Apartment[]): void;
    [MutationType.fetchTopics](state: State, topics: Topic[]): void;
    [MutationType.changeModal](state: State, value: boolean): void;
    [MutationType.assignWorker](state: State, ticket: Ticket): void;
    [MutationType.removeWorker](state: State, ticket: Ticket): void;
    [MutationType.assignMajor](state: State, ticket: Ticket): void;
    [MutationType.removeMajor](state: State, ticket: Ticket): void;
    [MutationType.updateTicket](state: State, ticket: Ticket): void;
    [MutationType.addLog](state: State, message: string): void;
    [MutationType.addMessageLog](state: State, message: Message): void;
    [MutationType.resetUnreadLogsCount](state: State): void;
    [MutationType.resetUnreadMessageLogsCount](state: State): void;
    [MutationType.updateLocation](state: State, location: Location): void;
    [MutationType.viewedTicket](state: State, id: number): void;
    [MutationType.viewedChat](state: State, id: number): void;
    [MutationType.setConnection](state: State, connection: WebSocket): void;
    [MutationType.changeConnectionStatus](state: State, connection: WebSocket): void;
    [MutationType.fetchChats](state: State, chats: Chat[]): void;
    [MutationType.fetchMessages](state: State, messages: Message[]): void;
    [MutationType.getMessage](state: State, message: Message): void;
    [MutationType.sendMessage](state: State, message: Message): void;
    [MutationType.pushMessageToActiveChat](state: State, message: Message): void;
    [MutationType.deleteMessage](state: State, message: Message): void;

    [MutationType.fetchOwners](state: State, owners: Owner[]): void;
    [MutationType.getOwner](state: State, owner: Owner): void;
    [MutationType.createOwner](state: State, owner: Owner): void;
    [MutationType.updateOwner](state: State, owner: Owner): void;
    [MutationType.deleteOwner](state: State, id: number): void;

    [MutationType.getWorkers](state: State, workers: Worker[]): void;
    [MutationType.getWorker](state: State, worker: Worker): void;
    [MutationType.createWorker](state: State, worker: Worker): void;
    [MutationType.updateWorker](state: State, worker: Worker): void;
    [MutationType.deleteWorker](state: State, id: number): void;
}

export const mutations: MutationTree<State> & Mutations = {
    [MutationType.setCredentials](state: State, credentials: Credentials): void {
        state.credentials = credentials;
    },
    [MutationType.unSetCredentials](state: State, value: boolean): void {
        console.log(value)
        state.credentials = null;
    },
    [MutationType.setConnection](state: State,connection: WebSocket): void {
        state.connection = connection;
    },
    [MutationType.changeConnectionStatus](state: State,connection: WebSocket): void {
        state.connection = connection;
    },
    [MutationType.changeModal](state: State,value: boolean): void {
        state.modalOpened = value;
    },
    [MutationType.addTicket](state: State, ticket: Ticket): void {
        state.tickets.unshift(ticket);
    },
    [MutationType.updateTicket](state: State, ticket: Ticket): void {

        const index = state.tickets.findIndex((existing: Ticket) => existing.id === ticket.id)
        if (index>=0){
            state.tickets[index] = ticket;
            state.tickets[index].justUpdated = true;
        }
    },
    [MutationType.addLog](state: State, message: string): void {
        const today = new Date();
        const date = today.getDate()+'.'+(today.getMonth()+1)+'.'+today.getFullYear();
        const time = today.getHours() + ":" + today.getMinutes();
            state.logList.push("'"+date+" "+time+"'..." + message)
            state.logs.push({ id: null, time: "'"+date+" "+time+"'...", ticketId: null, bookingId: null,
                messageId: null, name: message, description: message})
        state.unreadLogsCount++
    },
    [MutationType.addMessageLog](state: State, message: Message): void {
        // const today = new Date();
        // const date = today.getDate()+'.'+(today.getMonth()+1)+'.'+today.getFullYear();
        // const time = today.getHours() + ":" + today.getMinutes();
            if(state.user !== null && state.user.id !== message.userId) {
                state.logMessageList.push(message)
                state.unreadMessageLogsCount++
                state.logMessageList.sort(function (a, b) {
                    return a.created - b.created
                })
                state.logs.push({ id: null, time: message.createdDateFormatted, ticketId: message.ticketId, bookingId: message.bookingId,
                    messageId: message.id, name: message.message, description: message.message})
            }
    },
    [MutationType.resetUnreadLogsCount](state: State): void {
        state.unreadLogsCount = 0
    },
    [MutationType.resetUnreadMessageLogsCount](state: State): void {
        state.unreadMessageLogsCount = 0
    },
    [MutationType.updateLocation](state: State, location: Location): void {

        const index = state.resources.findIndex((coWorker: CoWorker) => coWorker.id === location.user_id)
        if (index>=0){
            state.resources[index].location = location;
            state.resources[index].justUpdated = true;
        }
    },
    [MutationType.viewedTicket](state: State, id: number): void {

        const index = state.tickets.findIndex((existing: Ticket) => existing.id === id)
        if (index>=0){
            state.tickets[index].justUpdated = false;
        }
    },

    [MutationType.viewedChat](state: State, id: number): void {

        const index = state.chats.findIndex((existing: Chat) => existing.id === id)
        if (index>=0){
            state.chats[index].justUpdated = false;
        }
    },

    [MutationType.setMainfilter](state: State, value: MainFilter): void {

        state.mainFilter.apartmentId = value.apartmentId;
        state.mainFilter.departmentId = value.departmentId;
        state.mainFilter.ownerId = value.ownerId;
        state.mainFilter.statusId = value.statusId;
        state.mainFilter.dateRange =  new Date(value.start).getDate()+'.'+(new Date(value.start).getMonth()+1) +'.'+ new Date(value.start).getFullYear()+' 00:00' +' to '+ (new Date(value.end).getDate()+1)+'.'+(new Date(value.end).getMonth()+1) +'.'+ new Date(value.end).getFullYear() +' 00:00',
        state.mainFilter.start = value.start;
        state.mainFilter.end = value.end;
    },
    [MutationType.setRangeMainFilter](state: State, range: string ): void {
        state.mainFilter.dateRange = range
    },
    [MutationType.setDatesMainFilter](state: State, dates: number[] ): void {
        state.mainFilter.start = dates[0];
        state.mainFilter.end = dates[1];
    },
    [MutationType.assignWorker](state: State, ticket: Ticket): void {

        const index = state.tickets.findIndex((existing: Ticket) => existing.id === ticket.id)
        if (index>=0){
            state.tickets[index] = ticket;
        }
    },
    [MutationType.removeWorker](state: State, ticket: Ticket): void {

        const index = state.tickets.findIndex((existing: Ticket) => existing.id === ticket.id)
        if (index>=0){
            state.tickets[index] = ticket;
        }
    },
    // [MutationType.assignWorker](state: State, ticket: Ticket): void {
    //
    //     const index = state.tickets.findIndex((existing: Ticket) => existing.id === ticket.id)
    //     if (index>=0){
    //         state.tickets[index] = ticket;
    //     }
    // },
    [MutationType.removeMajor](state: State, ticket: Ticket): void {

        const index = state.tickets.findIndex((existing: Ticket) => existing.id === ticket.id)
        if (index>=0){
            state.tickets[index] = ticket;
        }
    },
    [MutationType.assignMajor](state: State, ticket: Ticket): void {
        const index = state.tickets.findIndex((existing: Ticket) => existing.id === ticket.id)
        if (index>=0){
            state.tickets[index] = ticket;
        }
    },
    [MutationType.addObject](state: State, apartment: Apartment): void {
        state.lastAddedApartment = apartment;
        state.apartments.unshift(apartment);
    },
    [MutationType.updateObject](state: State, apartment: Apartment): void {
        const index = state.apartments.findIndex((ap: Apartment) => ap.id === apartment.id)
        if (index>=0){
            state.apartments[index] = apartment;
        }
    },
    [MutationType.fetchTickets](state: State, tickets: Ticket[]): void {
        state.tickets = tickets;
    },
    [MutationType.fetchPriorities](state: State, priorities: Priority[]): void {
        state.priorities = priorities;
    },
    [MutationType.fetchApartments](state: State, apartments: Apartment[]): void {
        state.apartments = apartments;
    },
    [MutationType.fetchTopics](state: State, topics: Topic[]): void {
        state.topics = topics;
    },
    [MutationType.fetchWorkersLoad](state: State, workers: WorkerLoad[]): void {
        state.workersLoad = workers;
    },
    [MutationType.fetchResources](state: State, workers: CoWorker[]): void {
        state.resources = workers;
    },
    [MutationType.setCurrentDepartment](state: State,  value: []): void {
        state.department = value;
    },
    [MutationType.setApartmentNameFilter](state: State,  value: string): void {
        state.apartmentNameFilter = value;
    },
    [MutationType.setCurrentPage](state: State,  page: number): void {
        state.currentPage = page;
    },
    [MutationType.setCurrentOffice](state: State,  value: []): void {
        state.office = value;
    },
    // [MutationType.setDeNameFilter](state: State,  value: []): void {
    //     state.department = value;
    // },
    [MutationType.setCurrentOffice](state: State,  value: []): void {
        state.department = value;
    },
    [MutationType.setFilterResources](state: State,  value: string): void {
        state.resourcesFilter = value;
    },
    [MutationType.setCurrentStatus](state: State,  value: []): void {
        state.status = value;
    },
    [MutationType.setCurrentDay](state: State,  value: Date): void {
        state.day = value;
        state.chooseDay = value.toLocaleString('ru-RU', {
            day:   '2-digit',
            month: '2-digit',
            year:  'numeric'
        });
    },
    [MutationType.getDepartments](state: State, departments: Department[]): void {
        state.departments = departments;
    },
    [MutationType.getStatuses](state: State,  statuses: Array<Map<number, string>>): void {
        state.statuses = statuses;
    },
    [MutationType.getTeams](state: State, teams: Team[]): void {
        state.teams = teams;
    },
    [MutationType.fetchTeams](state: State, teams: Team[]): void {
        state.teams = teams;
    },
    [MutationType.deleteTicket](state: State, id: number): void {
        state.tickets = state.tickets.filter((ticket: Ticket) => ticket.id !== id);
    },
    [MutationType.startTicket](state: State, id: number): void {
        const index = state.tickets.findIndex((ticket: Ticket) => ticket.id === id)
        if (index>=0){
            const ticket = state.tickets[index]
            ticket.status = "Processing"
            state.tickets[index] = ticket
        }
    },
    [MutationType.pauseTicket](state: State, id: number): void {
        const index = state.tickets.findIndex((ticket: Ticket) => ticket.id === id)
        if (index>=0){
            const ticket = state.tickets[index]
            ticket.status = "OnPause"
            state.tickets[index] = ticket
        }
    },
    [MutationType.finishTicket](state: State, id: number): void {
        const index = state.tickets.findIndex((ticket: Ticket) => ticket.id === id)
        if (index>=0){
            const ticket = state.tickets[index]
            ticket.status = "Finished"
            state.tickets[index] = ticket
        }
    },
    [MutationType.closeTicket](state: State, id: number): void {
        const index = state.tickets.findIndex((ticket: Ticket) => ticket.id === id)
        if (index>=0) {
            const ticket = state.tickets[index]
            ticket.status = "Closed"
            state.tickets[index] = ticket
        }
    },
    [MutationType.getUser](state: State, user: User| null): void {
            state.user = user
        if (user && user.id>0)
            state.isLoggedIn=true;
    },
    [MutationType.setUserLoggedIn](state: State, value: boolean ): void {
        console.log(value)
        state.isLoggedIn=true;
    },
    [MutationType.reopenTicket](state: State, id: number): void {
        const index = state.tickets.findIndex((ticket: Ticket) => ticket.id === id)
        if (index>=0){
            const ticket = state.tickets[index]
            ticket.status = "Opened"
            state.tickets[index] = ticket
        }
    },
    [MutationType.moveDown](state: State, id: number): void {
        const ticket: Ticket | undefined = state.tickets.find((ticket: Ticket) => ticket.id === id)
        if (ticket) {
            const index = state.tickets.indexOf(ticket as Ticket);
            if (index < state.tickets.length ) {
                const nextElement = state.tickets[index +1];
                const t = state.tickets[index];
                const orderNumber = nextElement.orderNumber;
                if (nextElement) {
                    state.tickets[index] = nextElement;
                    nextElement.orderNumber = t.orderNumber;
                    t.orderNumber = orderNumber;
                    state.tickets[index + 1] = t;
                    console.log("time to recalculate routes");
                }
            }
        }
    },
    [MutationType.moveUp](state: State, id: number): void {
        const ticket: Ticket | undefined = state.tickets.find((ticket: Ticket) => ticket.id === id)
         console.log(ticket);
        if (ticket) {
            // const index = state.tickets.indexOf(ticket as Ticket);
            const index: number = state.tickets.findIndex((ticket: Ticket) => ticket.id === id)
            if (index > 0 ) {
                state.tickets[index] = state.tickets.splice(index-1,1, state.tickets[index])[0];
                const oldNumber = state.tickets[index-1].orderNumber;
                state.tickets[index-1].orderNumber = state.tickets[index].orderNumber;
                state.tickets[index].orderNumber = oldNumber;
                // const prevElement: Ticket = state.tickets[index - 1];
                // const t: Ticket = state.tickets[index];
                // const orderNumber = prevElement.orderNumber;
                // if (prevElement) {
                //     state.tickets[index] = prevElement;
                //     prevElement.orderNumber = t.orderNumber;
                //     t.orderNumber = orderNumber;
                //     state.tickets[index - 1] = t;
                //     console.log("time to recalculate routes");
                // }
            }
        }
    },
    [MutationType.saveTicket](state: State, id: number): void {
        const ticket = state.tickets.find((ticket: Ticket) => ticket.id === id)
        if (ticket) {
            const index = state.tickets.indexOf(ticket as Ticket)
            console.log(index);
        }
    },
    [MutationType.setLoading](state, value) {
        state.loading = value
    },
    [MutationType.unSetUser](state,value) {
        console.log(value)
        state.user = null
        state.isLoggedIn = false
    },

     [MutationType.fetchChats](state: State, chats: Chat[]): void {
         state.chats = chats;
    },
     [MutationType.fetchMessages](state: State, messages: Message[]): void {
        messages.forEach ( (message) => {
            if (message.id) {
                const chat = state.chats.filter((chat) => chat.id === message.chat_id)
                if (chat.length == 1)
                    chat[0].messages.push(message);
            }
            state.messages.push(message);
        })
    },
     [MutationType.getMessage](state: State, message: Message): void {
        if (message.chat_id) {
            const chatIdx = state.chats.findIndex((chat) => chat.id === message.chat_id)
            if (chatIdx>0) {
                const idx = state.chats[chatIdx].messages.findIndex((chatMessage) => chatMessage.id === message.id)
                if ( idx < 0 ) {
                    state.chats[chatIdx].messages.push(message);
                    state.chats[chatIdx].justUpdated = true;
                    state.chats[chatIdx].unreadMessagesCount++;
                    state.chats[chatIdx].messages.sort(function (a, b) {
                        return a.created - b.created
                    });
                }
            }
        }
         state.messages.push(message);
         if (state.activeChat!== null && state.activeChat.id === message.chat_id) {
             const idx = state.activeChat.messages.findIndex((chatMessage) => chatMessage.id === message.id)
             if (idx < 0 ) {
                 state.activeChat.messages.push(message);
                 state.activeChat.justUpdated = false;
                 state.activeChat.unreadMessagesCount = 0;
                 state.activeChat.messages.sort(function (a, b) {
                     return a.created - b.created
                 });
             }
         }
    },
    [MutationType.sendMessage](state: State, message: Message): void {
        // const chats = state.chats.filter((chat) => chat.id === message.chat_id)
        // chats.forEach((chat)=>{
        //     const idx = chat.messages.findIndex((chatMessage)=> chatMessage.id === message.id)
        //     chat.messages[idx]
        // })
    },
    [MutationType.pushMessageToActiveChat](state: State, message: Message): void {
        if (state.activeChat !== null) {
            const idx = state.activeChat.messages.findIndex((chatMessage) => chatMessage.id === message.id)
            if (idx < 0) {
                state.activeChat.messages.push(message);
                state.activeChat.justUpdated = true;
                state.activeChat.messages.sort(function (a, b) {
                    return a.created - b.created
                });
            }
        }
    },
    [MutationType.deleteMessage](state: State, message: Message): void {
        debugger
         const chats = state.chats.filter((chat) => chat.id === message.chat_id)
         chats.forEach((chat)=> {
             const idx = chat.messages.findIndex((chatMessage)=> chatMessage.id === message.id)
             if(idx >0)
              chat.messages.splice(idx,1);
             });
         if (state.activeChat !== null) {
             const idx = state.activeChat.messages.findIndex((chatMessage)=> chatMessage.id === message.id)
             if(idx >0)
                state.activeChat.messages.splice(idx,1);
         }
    },
     [MutationType.fetchOwners](state: State, owners: Owner[]): void {
            state.owners = owners;
    },
    [MutationType.getOwner](state: State, owner: Owner): void {
        const idx = state.owners.findIndex((existing) => existing.id === owner.id)
        if (idx >0)
            state.owners[idx] = owner;
        else state.owners.unshift(owner);
        state.lastAddedOwner = owner;
    },
    [MutationType.createOwner](state: State, owner: Owner): void {
        state.lastAddedOwner = owner;
        state.owners.push(owner);
    },
    [MutationType.updateOwner](state: State, owner: Owner): void {
        const idx = state.owners.findIndex((existing) => existing.id === owner.id)
        if (idx >0)
            state.owners[idx] = owner;
    },
    [MutationType.deleteOwner](state: State, id: number): void {
        const idx = state.owners.findIndex((owner) => owner.id === id)
        if (idx >0)
            state.owners.splice(idx,1);
    },

     [MutationType.getWorkers](state: State, workers: Worker[]): void {
            state.workers = workers;
    },
    [MutationType.getWorker](state: State, worker: Worker): void {
        const idx = state.workers.findIndex((existing) => existing.id === worker.id)
        if (idx >0)
            state.workers[idx] = worker;
        else state.workers.unshift(worker);
        state.lastAddedWorker = worker;
    },
    [MutationType.createWorker](state: State, worker: Worker): void {
        state.lastAddedWorker = worker;
        state.workers.push(worker);
    },
    [MutationType.updateWorker](state: State, worker: Worker): void {
        const idx = state.workers.findIndex((existing) => existing.id === worker.id)
        if (idx >0)
            state.workers[idx] = worker;
    },
    [MutationType.deleteWorker](state: State, id: number): void {
        const idx = state.workers.findIndex((worker) => worker.id === id)
        if (idx >0)
            state.workers.splice(idx,1);
    },
    [MutationType.setActiveChatId](state: State, value: number): void {
            state.activeChatId = value;
    },
    [MutationType.setActiveChat](state: State, value: Chat): void {
            state.activeChat = value;
            state.activeChat.unreadMessagesCount = 0;
            state.activeChat.justUpdated = false;
            const currentMessages = state.logMessageList.filter(message => message.chat_id === value.id)
            currentMessages.forEach(message => {
                const idx = state.logMessageList.findIndex(log => log.id === message.id)
                if(idx>0){
                    state.logMessageList.splice(idx,1)
                    state.unreadMessageLogsCount--
                }
            })
        state.logMessageList.sort(function (a, b) {
            return a.created - b.created
        })
    }
}
