import User from "@/models/user";
import Location from "@/models/location/location";

 const GuestUser: User = {
    id: -1,
    username: "Guest",
    email: "email@email.hr",
    name: "Guest",
    roles: ["guest"],
    status: null,
    myRentId: -1,
    sleepingPlace: "",
    sleepingPoint: [14.234,45.234],
    avatarLink: "https://extranet.ronabooking.com/Images/no_user.jpg",
    location: null,
    justUpdated: false
}

export default GuestUser;