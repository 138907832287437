import httpClient from "./http.service";
import {Owner} from "@/models/owner";

const ownerService = {
    async fetchOwners(userId: number) {
        if (userId !== null && userId !== undefined)
            return httpClient.get('owners?userId=' + userId)
        else return httpClient.get('owners')
    },
    async getOwner(id: number) {
        return httpClient.get('message?id=' + id)
    },
    async createOwner(owner: Owner) {

        return httpClient.post('owner',  owner)
    },
    async updateOwner(owner: Owner) {
        return httpClient.post('owner/update?id='+owner.id, owner)
    },
    async deleteOwner(id: number) {
        return httpClient.delete('owner/delete?id='+ id)
    }
};

export default ownerService;


/**
 'GET owners' => 'owner/index',
 'GET owner' => 'owner/view',
 'POST owner' => 'owner/create',
 'POST owner/update' => 'owner/update',
 'DELETE owner/delete' => 'owner/delete',
 */