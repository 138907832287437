import {GetterTree} from 'vuex'
import {State} from './state'
import Ticket from "@/models/ticket";
import Department from "@/models/department";
import Team from "../models/team"
import User from "../models/user"
import WorkerLoad from "@/models/worker-load";
import {CoWorker} from "@/models/co-worker";
import Topic from "@/models/topic";
import Apartment from "@/models/apartment";
import Priority from "@/models/priority";
import {workers} from "@/Data/workers";
import TicketForm from "@/models/ticket-form";
import {Owner} from "@/models/owner";
import {Chat} from "@/models/chat/chat";
import {Worker} from "@/models/worker";
import {Message} from "@/models/chat/message";



export type Getters = {
    allTickets(state: State): Ticket [];
    filteredTickets(state: State): Ticket [];
    departments(state: State): Department [];
    priorities(state: State): Priority [];
    prioritiesOptions(state: State): {value: number; name: string}[];
    apartments(state: State): Apartment [];
    filteredApartments(state: State): Apartment [];
    filteredResources(state: State): CoWorker [];
    resources(state: State): CoWorker [];
    owners(state: State): Owner [];
    getChats(state: State): Chat [];
    getLogs(state: State): string [];
    getMessageLogs(state: State): Message [];
    lastAddedOwner(state: State): (Owner | null);
    lastAddedApartment(state: State): (Apartment | null);
    apartmentsOptions(state: State): {value: number; name: string}[];
    departmentsOptions(state: State): {value: number; name: string}[];
    topics(state: State): Topic [];
    topicsOptions(state: State):  {value: number; name: string}[];
    statuses(state: State):  Array<Map<number, string>>;
    statusesOptions(state: State):  {value: number|string|undefined; name: string|undefined}[];
    chooseDay(state: State):  string;
    currentDay(state: State):  Date;
    currentFormattedDay(state: State):  string;
    teams(state: State): Team [];
    ticketsCount(state: State): number;
    getTicketById(state: State): (id: number) => Ticket | undefined;
    getApartmentById(state: State): (id: number) => Apartment | undefined;
    filteredActiveChatMessages(state: State): (searchString: string) => Message [];
    filteredChats(state: State): (searchString: string) => Chat [];
    // getActiveChat(state: State): (id: number|null) => Chat | undefined| null;
    getActiveChat(state: State):  Chat | null;
    getUnreadMessagesGlobalCount(state: State):  number;
    getUnreadMessageLogsCount(state: State):  number;
    getWorkerById(state: State): (id: number) => Worker | undefined;
    getUser(state: State): User|null;
    isLoggedIn(state: State): boolean;
    loading(state: State): boolean;
    modalOpened(state: State): boolean;
    connection(state: State): WebSocket|null;
    resourcesFilter(state: State): string;
    // isLoading(state: State): boolean;
    // getWorkingTime(state: State, id: number): number;
}

export const getters: GetterTree<State, State> & Getters = {
    allTickets(state: State): Ticket [] {
        return state.tickets
    },
    connection (state: State): WebSocket|null {
        return state.connection;
    },
    // getWorkingTime(state: State, id: number): number {
    //     let time = 0;
    //     state.tickets.forEach((ticket) =>  {
    //         if (ticket.coWorkers.filter((worker) => worker.id === id).length > 0 || ticket.major.id === id)
    //             time += ticket.timePerWorker
    //     })
    //     return time;
    // },
    // getTravellingTime(state: State, id: number): number {
    //     let time = 0;
    //     state.tickets.forEach((ticket) =>  {
    //         if (ticket.coWorkers.filter((worker) => worker.id === id).length > 0 || ticket.major.id === id)
    //             time += ticket.timePerWorker
    //     })
    //     return time;
    // },
    filteredTickets (state: State): Ticket [] {
        console.log("we are in getter , store department = ", state.department +', store status = ,' + state.status)
        let tickets = state.tickets;
        if (state.department.length > 0) {
            tickets = tickets.filter((ticket) =>  state.department.includes(ticket.departmentId))
        }
        if (state.status.length > 0) {
            tickets = tickets.filter((ticket) =>  state.status.includes(ticket.statusId))
        }

        return tickets
    },
    filteredActiveChatMessages: (state) => (searchString: string) => {
        debugger
        let messages: Message [] = [];
        if (state.activeChat !== null) {
             messages = state.activeChat.messages;
            if (searchString.length > 0) {
                const needle = searchString.toLowerCase()
                messages = messages.filter((message) =>
                    ((message.message !== null && message.message !== '' && message.message !== undefined )? message.message.toLowerCase().includes(needle):
                        (message.message !== null && message.message !== undefined)? message.message.includes(needle) : true)
                    || ((message.bookingId !== null)?message.bookingId.toString().includes(needle): false)
                    || ((message.ticketId !== null)?message.ticketId.toString().includes(needle): false)
                    || ((message.chat_id !== null)?message.chat_id.toString().includes(needle): false)
                    || message.type.toLowerCase().includes(needle)
                    || message.author.toLowerCase().includes(needle)
                    || message.createdDateFormatted.toLowerCase().includes(needle))
            }
        }
        return messages
    },
    filteredChats: (state) => (searchString: string) => {
        let chats = state.chats;
        if (searchString.length > 0) {
            debugger
            const needle = searchString.toLowerCase()
            chats = chats.filter((chat) => chat.messages.findIndex((message) => ((message.message !== null && message.message !== '' && message.message !== undefined )?
                message.message.toLowerCase().includes(needle):(message.message !== null && message.message !== undefined)? message.message.includes(needle) : true)
                || ((message.bookingId !== null)?message.bookingId.toString().includes(needle): false)
                || ((message.ticketId !== null)?message.ticketId.toString().includes(needle): false)
                || ((message.chat_id !== null)?message.chat_id.toString().includes(needle): false)
                || message.type.toLowerCase().includes(needle)
                || message.author.toLowerCase().includes(needle)
                || message.createdDateFormatted.toLowerCase().includes(needle)) >0
                || chat.participants.findIndex((participant) => (participant.name !== null)? participant.name.toLowerCase().includes(needle) :-1) >0
                || ((chat.rentId !== null)?chat.rentId.toString().includes(needle): false)
                || ((chat.ticketId !== null)?chat.ticketId.toString().includes(needle): false)
                || ((chat.bookingId !== null)?chat.bookingId.toString().includes(needle): false)
                || ((chat.id !== null)?chat.id.toString().includes(needle): false)
                || ((chat.ticketNumber !== null)?chat.ticketNumber.toString().includes(needle): false)
                || ((chat.name !== null)? chat.name.toLowerCase().includes(needle) : false)
                || ((chat.descryption !== null)?chat.descryption.toLowerCase().includes(needle): false)
                || ((chat.author !== null)?chat.author.toLowerCase().includes(needle): false)
                || chat.createdDateFormatted.toLowerCase().includes(needle)
                || chat.rentAuthor.toLowerCase().includes(needle)
            )
        }
        return chats
    },

    mainFilteredTickets (state: State): Ticket [] {

        let tickets = state.tickets;
        if (state.mainFilter.departmentId.length > 0) {
            tickets = tickets.filter((ticket) =>  state.mainFilter.departmentId.includes(ticket.departmentId))
        }
        if (state.mainFilter.statusId.length > 0) {
            tickets = tickets.filter((ticket) =>  state.mainFilter.statusId.includes(ticket.statusId))
        }
        if (state.mainFilter.ownerId.length > 0) {
            tickets = tickets.filter((ticket) =>  state.mainFilter.ownerId.includes(ticket.ownerId))
        }
        if (state.mainFilter.apartmentId.length > 0) {
            tickets = tickets.filter((ticket) =>  state.mainFilter.apartmentId.includes(ticket.apartmentId))
        }

        return tickets
    },

    filteredApartments (state: State): Apartment [] {

        let apartments = state.apartments;
        // if (state.office.length > 0) {
        //     apartments = apartments.filter((apartment) =>  state.office.includes(apartment.office))
        // }
        if (state.apartmentNameFilter.length > 0) {
            apartments = apartments.filter((apartment) =>  (apartment.name.toLowerCase().indexOf(state.apartmentNameFilter.toLowerCase())!==-1)||
                ((apartment.address !== null)?(apartment.address.toLowerCase().indexOf(state.apartmentNameFilter.toLowerCase())!==-1):0))
        }
        const pagedApartment = [];
        const maxNumber = (apartments.length > (state.pageSize*(state.currentPage)))? state.pageSize*(state.currentPage): apartments.length
        for (let i= state.pageSize*(state.currentPage-1); i < maxNumber; i++) {
            pagedApartment.push(apartments[i + (state.currentPage-1)*state.pageSize])
        }
        return pagedApartment
    },
    filteredWorkers (state: State): WorkerLoad [] {
        if (state.workersLoad.length>0) {
            let workers = state.workersLoad.filter((worker) => worker.workingTime > 0)
            if (state.department.length > 0) {
                workers = workers.filter((worker) => state.department.includes(worker.departmentId))
            }
            return workers
        }
        return []
    },

    filteredResources (state: State): CoWorker [] {
        let resources = state.resources;
        if (state.department.length > 0) {
            resources = resources.filter((worker) => state.department.includes(worker.departmentId))
        }

        if (state.resourcesFilter.length > 0) {
            resources = resources.filter((worker) => worker.name.toLowerCase().indexOf(state.resourcesFilter.toLowerCase())!==-1)
        }
        return resources
    },
    resources (state: State): CoWorker [] {
        return state.resources
    },
    resourcesFilter(state: State): string {
        return state.resourcesFilter;
    },
    priorities(state: State): Priority [] {
        return state.priorities
    },
    prioritiesOptions(state: State): {value: number; name: string}[] {
        const result: {value: number; name: string}[] = [];
        state.priorities.forEach(function(element: Priority) {
            result.push({value: element.id, name: element.priority});
        })
        return result;
    },
    departmentsOptions(state: State): {value: number; name: string}[] {
        const result: {value: number; name: string}[] = [];
        state.departments.forEach(function(element: Department) {
            result.push({value: element.id, name: element.name});
        })
        return result;
    },
    apartments(state: State): Apartment [] {
        return state.apartments
    },
    apartmentsOptions(state: State): {value: number; name: string}[] {
        const result: {value: number; name: string}[] = [];
        state.apartments.forEach(function(element: Apartment) {
            result.push({value: element.id, name: element.name});
        })
        return result;
    },
    topics(state: State): Topic [] {
        return state.topics
    },
    topicsOptions(state: State): {value: number; name: string}[] {
        const result: {value: number; name: string}[] = [];
        state.topics.forEach(function(element: Topic) {
            result.push({value: element.id, name: element.name});
        })
        return result;
    },
    departments(state: State): Department [] {
        return state.departments
    },
    statuses(state: State):  Array<Map<number, string>> {

        return state.statuses
    },
    statusesOptions(state: State): {value: number|string|undefined; name: string|undefined}[] {
        const result: {value: number|string|undefined; name: string|undefined}[] = [];
        state.statuses.forEach(function(element: Map<number, string>) {
            if (element.get(0) !== undefined && element.get(1) !== undefined)
            result.push({value: element.get(0), name: element.get(1)});
        })
        return result;
    },
    getUser(state: State): User|null {
        return state.user
    },
    teams(state: State): Team [] {
        return state.teams
    },
    currentDay(state: State): Date {
        return state.day
    },
    chooseDay(state: State): string {
        return state.day.toLocaleString('ru-RU', {
            day:   '2-digit',
            month: '2-digit',
            year:  'numeric'
        });
    },
    currentFormattedDay(state: State): string {
        return state.day.toLocaleString('ru-RU', {
            day:   '2-digit',
            month: '2-digit',
            year:  'numeric'
        });
    },
    ticketsCount(state: State): number {
        return state.tickets.length;
    },
    getTicketById: (state) => (id: number) => {
        return state.tickets.find(ticket => ticket.id === Number(id))
    },
    getApartmentById: (state) => (id: number) => {
        return state.apartments.find(apartment => apartment.id === Number(id))
    },
    getActiveChat(state): Chat | null {
        return state.activeChat;
    },
    // getActiveChatId: (state) => () => {
    //     return state.activeChatId
    // },
    getWorkerById: (state) => (id: number) => {
        return state.workers.find(worker => worker.id === Number(id))
    },
    isLoggedIn(state: State): boolean {
        return state.isLoggedIn;
    },

    loading(state: State): boolean {
        return state.loading;
    },
    modalOpened(state: State): boolean {
        return state.modalOpened;
    },
    owners(state: State): Owner[] {
        return state.owners;
    },
    getChats(state: State): Chat[] {
        return state.chats;
    },
    getLogs(state: State): string[] {
        return state.logList;
    },
    getMessageLogs(state: State): Message [] {
        return state.logMessageList;
    },
    getUnreadMessagesGlobalCount(state: State): number {
        let count = 0
         state.chats.forEach((chat)=>{
             count = chat.unreadMessagesCount
        });
        return count
    },
    getUnreadLogsCount(state: State): number {
        return state.unreadLogsCount;
    },
    getUnreadMessageLogsCount(state: State): number {
        return state.unreadMessageLogsCount;
    },
    lastAddedOwner(state: State): (Owner | null) {
        return state.lastAddedOwner;
    },
    lastAddedApartment(state: State): (Apartment | null) {
        return state.lastAddedApartment;
    }
}
