import httpClient from "./http.service";
import Ticket from "@/models/ticket";
import {RequestTickets} from "@/models/requestTickets";
import Apartment from "@/models/apartment";
import TicketForm from "@/models/ticket-form";


const ticketsService = {
    async create(form: TicketForm) {
        //return httpClient.post('ticket/new', form)

        console.log("add ticket :" + form)
        return httpClient.post('ticket/add', form)
    },
    async get(day: number | string | undefined, status: string|number|number[]|null, userId: string|number|number[]|null = localStorage.getItem("user_id")) {
        const requestedDay = (day !== undefined && day !== null && day >0 )? day : (new Date()).getSeconds()
        const requestedStatus = (status !== undefined && status !== '' )? status : "All"
        const requestedUser = (userId !== undefined && userId !== null && userId !== '' )? userId : localStorage.getItem("user_id")
        if (userId === undefined || userId !== null || userId !== '' ) {
            console.log('helpdesk?day=' + requestedDay + '&type='+requestedStatus)
            return httpClient.get('helpdesk?day=' + requestedDay + '&type=' + requestedStatus)
        }
        else {
            console.log('helpdesk?day=' + requestedDay + '&type='+requestedStatus+'&forUser?='+requestedUser)
            return httpClient.get('helpdesk?day=' + requestedDay + '&type='+requestedStatus+'&forUser?='+requestedUser)
        }

    },
    async getWithRequestForm(request: RequestTickets) {
        const dayInSeconds = (request.day!==null && request.day>0) ? request.day : ((new Date()).getTime()/1000).toFixed(0);
        const requestedStatus = (request.status !== undefined && request.status !== '' )? request.status : "All"
        const requestedUser = (request.user !== undefined && request.user !== null && request.user !== '' )? request.user : localStorage.getItem("user_id")
        if (request.user === undefined || request.user !== null || request.user !== '' )
            return httpClient.get('helpdesk?day=' + dayInSeconds + '&type='+requestedStatus)
        return httpClient.get('helpdesk?day=' + dayInSeconds + '&type='+requestedStatus+'&forUser?='+requestedUser +
            ((request.department !== undefined)? '&dept_id?='+request.department:'')
        )
    },
    async getWithQuery(request: RequestTickets) {
        debugger
        return httpClient.post('tickets/filter', request.map())
    },
    async getSettings( apartment: number|null, topic: number|null) {
        if (apartment)
            return httpClient.get('/helpdesk/settings?'+'apartment_id=' + apartment + ((topic)?('&help_topic_id=' + topic):'') )
        else if (topic)
            return httpClient.get('/helpdesk/settings?help_topic_id=' + topic )
    },
    async getWorkersLoading( day: number) {
        return httpClient.get('helpdesk/workers?day=' + day)
    },
    async getWorkersResources( day: number) {
        return httpClient.get('helpdesk/resources?day=' + day)
    },
    async delete(id: number) {
        return httpClient.put('ticket/delete?ticketId=' + id)
    },
    start: async function (id: number) {
        return httpClient.get('ticket/start?id=' + id)
    },
    async pause(id: number) {
        return httpClient.get('ticket/pause?id=' + id)
    },
    async finish(id: number) {
        return httpClient.get('ticket/finish?id=' + id)
    },
    async close(id: number) {
        return httpClient.post('ticket/close?ticketId=' + id)
    },
    async reopen(id: number) {
        return httpClient.post('ticket/reopen?ticketId=' + id)
    },
    async moveUp(id: number, day: number) {
        return httpClient.post('ticket/move-up?id=' + id+"&date="+day)
    },
    async moveDown(id: number, day: number) {
        return httpClient.post('ticket/move-down?id=' + id+"&date="+day)
    },
    async newObject(apartment: Apartment) {
        console.log(apartment)
        return httpClient.post('apartment/add-new', apartment)
    },
    async updateObject(apartment: Apartment) {
        console.log(apartment)
        debugger
        return httpClient.post('apartment/update?id='+apartment.id, apartment)
    },
    async update(form: TicketForm) {
        debugger
        return httpClient.put('helpdesk/update', form)
    },
    async assignCoWorker(id: number, ticketId: number) {
        return httpClient.post('ticket/assign-co-worker?ticketId='+ticketId+'&userId='+id)
    },
    async assignMajor(id: any, ticketId: any) {
        return httpClient.post('ticket/assign-major?ticketId='+ticketId+'&userId='+id)
    },
    removeCoWorker(id: number, ticketId: number) {
        return httpClient.post('ticket/remove-co-worker?ticketId='+ticketId+'&userId='+id)
    },
    removeMajor(id: number, ticketId: number) {
        return httpClient.post('ticket/remove-major?ticketId='+ticketId+'&userId='+id)
    },
    ticket(id: number) {
        return httpClient.get('ticket?id='+id)
    },
    getObject(id: number) {
        return httpClient.get('apartment?id='+id)
    },
    getForDateRange(dateRange: string, userId: number) {
        return httpClient.get('tickets/range?range=' + dateRange+'&forUser?='+userId)
    }
};

export default ticketsService;
