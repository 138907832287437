import Ticket from "@/models/ticket";
import Department from "@/models/department";
import Team from "../models/team"
import User from "@/models/user";
import WorkerLoad from "@/models/worker-load";
import {CoWorker} from "@/models/co-worker";
import Apartment from "@/models/apartment";
import Topic from "@/models/topic";
import Priority from "@/models/priority";
import TicketForm from "@/models/ticket-form";
import {Chat} from "@/models/chat/chat";
import {Message} from "@/models/chat/message";
import {Owner} from "@/models/owner";
import {Worker} from "@/models/worker";
import {Credentials} from "@/models/credentials/credentials";
import {MainFilter} from "@/models/filter/main-filter";
import EventLog from "@/models/event-log";

export type State = {
    credentials: Credentials|null;
    messages: Message [];
    loading: boolean;
    tickets: Array<Ticket>;
    filteredTicket: Array<Ticket>;
    departments: Array<Department>;
    statuses: Array<Map<number, string>>;
    status: Array<number>;
    day: Date;
    chooseDay: string;
    department: Array<number>;
    teams: Array<Team>;
    user: User|null;
    isLoggedIn: boolean;
    workersLoad: Array<WorkerLoad>;
    resources: Array<CoWorker>;
    apartments: Array<Apartment>;
    topics: Array<Topic>;
    priorities: Array<Priority>;
    modalOpened: boolean;
    ticketForm: TicketForm|null;
    connection: WebSocket|null;
    chats: Chat [];
    owners: Owner [];
    lastAddedOwner: Owner|null;
    lastAddedApartment: Apartment|null;
    currentWorker: Worker|null;
    workers: Worker[];
    lastAddedWorker: Worker|null;
    resourcesFilter: string;
    office: string [];
    apartmentNameFilter: string;
    currentPage: number;
    pageSize: number;
    mainFilter:  MainFilter;
    activeChatId:  number|null;
    activeChat: Chat|null;
    logList: string[];
    unreadLogsCount: number;
    logMessageList: Message [];
    unreadMessageLogsCount: number;
    logs: EventLog [];
}

export const state: State = {
    credentials: null,
    loading: false,
    tickets: [],
    filteredTicket: [],
    departments: [],
    statuses: [],
    status: [],
    teams: [],
    day: new Date(),
    chooseDay: (new Date()).toLocaleString('ru-RU', {
        day:   '2-digit',
        month: '2-digit',
        year:  'numeric'
    }),
    user: null,
    isLoggedIn: false,
    department: [],
    workersLoad:[],
    resources:[],
    apartments : [],
    topics : [],
    priorities :[],
    modalOpened: false,
    ticketForm: null,
    connection: null,
    chats:[],
    messages:[],
    owners: [],
    lastAddedOwner: null,
    lastAddedApartment: null,
    currentWorker: null,
    workers:[],
    lastAddedWorker: null,
    resourcesFilter:"",
    office:[],
    apartmentNameFilter:"",
    currentPage:1,
    pageSize:10,
    activeChatId:null,
    activeChat: null,
    mainFilter: {
        apartmentId: [],
        departmentId: [],
        ownerId: [],
        statusId: [],
        dateRange: new Date().getDate()+'.'+(new Date().getMonth()+1) +'.'+ new Date().getFullYear()+' 00:00' +' to '+ (new Date().getDate()+1)+'.'+(new Date().getMonth()+1) +'.'+ new Date().getFullYear() +' 00:00',
        start: new Date((new Date().getMonth()+1)+ '/'+(new Date().getDate()) +'/'+ new Date().getFullYear()).getTime(),
        end: new Date((new Date().getMonth()+1)+ '/'+(new Date().getDate()+1) +'/'+ new Date().getFullYear()).getTime(),
    },
    logList:[],
    unreadLogsCount: 0,
    logMessageList: [],
    unreadMessageLogsCount: 0,
    logs: [],
}
