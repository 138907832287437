
import { createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import { store } from "./store";


import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

// import 'bootstrap-vue/dist/bootstrap-vue.css'

// import {  IconsPlugin } from 'bootstrap-vue'
// import  BootstrapVue  from 'bootstrap-vue'


// import { BootstrapVue, BootstrapVueIcons, IconsPlugin, FormInputPlugin } from 'bootstrap-vue'
// import { BootstrapVue, FormInputPlugin } from 'bootstrap-vue'

// App.component('font-awesome-icon', FontAwesomeIcon)
import { FontAwesomeIcon } from "@/plugins/font-awesome";

// import {Vue} from "vue-class-component";
// Before
// Vue.prototype.$http = () => {}
import './assets/app.scss'

const app = createApp(App)
app.component("fa", FontAwesomeIcon);

// app.config.globalProperties.$http = () => {}

const vm = app.use(store).use(router).mount('#app')


// App.use(BootstrapVue);App.use(FormInputPlugin);
// App.use(BootstrapVueIcons);
// App.use(IconsPlugin);


// const RootComponent = {
//     component: () => import( '../views/Home.vue')
// }
//
//
// Vue.createApp({
//     RootComponent
// }).mount('#app')
