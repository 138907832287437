import axios, {AxiosRequestConfig} from 'axios';
import authService from "./auth.service";
import router from '../router';

/** Default config for axios instance */
// const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'http://api.selfin.local/';
// const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'http://localhost:8081/';
// const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'http://localhost:8080/';
// const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || '/api';
// const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'http://localhost:8080/';
// const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || '/';
// const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'https://api.selfin.my-rent.net/';
const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT || 'https://api.extranet.ronabooking.com/';
const config = {
    baseURL: `${API_ENDPOINT}`
};

/** Creating the instance for axios */
const httpClient = axios.create(config);

/** Auth token interceptors */
const authInterceptor = (config: AxiosRequestConfig) => {
    if (config.url !== 'board-login' && config.url !== 'auth' && config.url !== 'hello' && config.url !== 'password-email')
        config.headers.Authorization = `Bearer ${authService.getToken()}`;
    return config;
};

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor);

/** Adding the response interceptors */
httpClient.interceptors.response.use(
    response => {
        /** TODO: Add any response interceptors */
        return response;
    },
    error => {
        if (error.response.status === 401) {
            router.push({name: 'login'})
        }
        /** TODO: Do something with response error */
        return Promise.reject(error);
    }
);

export default httpClient;
