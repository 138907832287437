import httpClient from "./http.service";
import {Worker} from "@/models/worker";

const workerService = {
    async getWorkers(userId: number) {
        return httpClient.get('workers?userId=' + userId)
    },
    async getWorker(id: number) {
        return httpClient.get('worker?id=' + id)
    },
    async createWorker(worker: Worker) {
        const formData = new FormData();

        if (worker.files.length>0) {
        // for( let i = 0; i < worker.files.length; i++ ){
        //     const file = worker.files[i];
        //     formData.append('PhotosForm[files][' + i + ']', file);
        // }
            formData.append('WorkerForm', JSON.stringify(worker) )

            return httpClient.post('worker',  formData,{
                headers: {
                    'Content-Type': 'multipart/form-data'
                }})
        }
        else  return httpClient.post('worker',worker);
    },
    async updateWorker(worker: Worker) {
        return httpClient.post('worker/update?id='+worker.id, worker)
    },
    async setAvatar(formData: FormData, id: number) {
        return httpClient.post('avatar?id='+ id, formData)
    },
    async deleteWorker(id: number) {
        return httpClient.delete('worker/delete?id='+ id)
    },
    async checkIn(form: { department_id: number; latitude: number ; id: number; team_id: []; longitude: number ; address: string  }) {

        return httpClient.post('worker/checkin?id='+form.id, form)
    }
};

export default workerService;


/**
 'GET workers' => 'worker/index',
 'GET worker' => 'worker/view',
 'POST worker' => 'worker/create',
 'POST worker/update' => 'worker/update',
 'DELETE worker/delete' => 'worker/delete',
 */