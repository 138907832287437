import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";
import FontAwesomeIcon from '@fortawesome/vue-fontawesome';
import { dom } from '@fortawesome/fontawesome-svg-core'
import {fab} from "@fortawesome/free-brands-svg-icons";
import { far } from '@fortawesome/free-regular-svg-icons';

// library.add(fas, faTwitter, faInstagram);
library.add(fas);
library.add(fab, far);
dom.watch();

export { FontAwesomeIcon };

/****https://halilyuce.com/web/how-to-add-font-awesome-to-your-vue-3-project-typescript/
 *Brand Logo Icon Example:

 <fa icon="twitter" type="fab" class="classname"></fa>

 Regular Icon Example:

 <fa icon="align-right" class="classname"></fa>



 JavaScript
 <template>
 <svg
 xmlns="http://www.w3.org/2000/svg"
 :class="$props.class"
 :viewBox="`0 0 ${width} ${height}`"
 >
 <path fill="currentColor" :d="svgPath" />
 </svg>
 </template>

 <script>
 import { defineComponent, computed } from "vue";
 import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

 export default defineComponent({
  name: "FontAwesomeIcon",

  props: {
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "fas",
      required: false
    },
    class: String
  },

  setup(props) {
    const definition = computed(() =>
      findIconDefinition({
        prefix: props.type,
        iconName: props.icon
      })
    );

    const width = computed(() => definition.value.icon[0]);
    const height = computed(() => definition.value.icon[1]);
    const svgPath = computed(() => definition.value.icon[4]);

    return { width, height, svgPath };
  }
});
 </script>
 <template>
 <svg
 xmlns="http://www.w3.org/2000/svg"
 :class="$props.class"
 :viewBox="`0 0 ${width} ${height}`"
 >
 <path fill="currentColor" :d="svgPath" />
 </svg>
 </template>

 <script>
 import { defineComponent, computed } from "vue";
 import { findIconDefinition } from "@fortawesome/fontawesome-svg-core";

 export default defineComponent({
  name: "FontAwesomeIcon",

  props: {
    icon: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "fas",
      required: false
    },
    class: String
  },

  setup(props) {
    const definition = computed(() =>
      findIconDefinition({
        prefix: props.type,
        iconName: props.icon
      })
    );

    const width = computed(() => definition.value.icon[0]);
    const height = computed(() => definition.value.icon[1]);
    const svgPath = computed(() => definition.value.icon[4]);

    return { width, height, svgPath };
  }
});
 </script>


 * **/