import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'
import authService from "@/services/auth.service";
import {useStore} from "@/store";


const store = useStore();
// function requireAuth() {
//   if (localStorage.getItem("USER_ID") && localStorage.getItem("USER_ROLES") && localStorage.getItem("USER_ID")>0) {
//     return true;
//   }
//   return false;
// }

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import( '../layouts/DefaultLayout.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'tickets',
        name: 'tickets',
        component: () => import('@/components/TicketsList.vue'),
        children: [
          {
            path: 'view/:id',
            name: 'view',
            props: true,
            component: () => import( '@/components/TicketInfo.vue')
          },
          // {
          //   path: 'worker/new',
          //   name: 'worker/new',
          //   props: true,
          //   component: () => import( '@/components/AddWorker.vue')
          // },
          // {
          //   path: 'worker/card/:id',
          //   name: 'worker/card',
          //   props: true,
          //   component: () => import( '@/components/AddWorker.vue')
          // },
        //   {
        //     path: 'create',
        //     name: 'create',
        //     component: () => import( '../views/AddTicket.vue')
        //   },
        ]
      },
      {
        path: 'worker/new',
        name: 'worker/new',
        props: true,
        component: () => import( '@/components/AddWorker.vue')
      },
      {
        path: 'leaflet',
        name: 'leaflet',
        props: true,
        component: () => import( '@/components/Leaflet.vue')
      },
      {
        path: 'card/:id',
        name: 'card',
        props: true,
        component: () => import( '@/components/AddWorker.vue')
      },
      {
        path: 'create',
        name: 'create',
        component: () => import( '@/components/AddTicket.vue')
      },
      {
        path: 'view/:id',
        name: 'view',
        props: true,
        component: () => import( '@/components/TicketInfo.vue')
      },
      {
        path: 'clone',
        name: 'clone',
        component: () => import( '@/components/clone.vue')
      },
      {
        path: 'create-object',
        name: 'create-object',
        component: () => import( '@/components/AddObject.vue')
      },
      {
        path: 'map',
        name: 'map',
        component: () => import( '@/components/MapComponent.vue')
      },
      // {
      //   path: 'view',
      //   name: 'Ticket View',
      //   component: () => import( '../views/TicketView.vue')
      // },
      {
        path: 'map',
        name: 'Map View',
        component: () => import( '../views/About.vue')
      },
      // {
      //   path: 'chats/:id',
      //   name: 'chats',
      //   props: true,
      //   component: () => import( '@/components/ChatList.vue')
      // },
      {
        path: 'chats/:id/:chatId',
        name: 'chats',
        props: true,
        component: () => import( '@/components/ChatList.vue')
      },
      {
        path: '/apartments',
        name: 'apartments',
        component: () => import( '@/components/ApartmentList.vue')
        //component: Login
      },
      {
        path: '/workers',
        name: 'workers',
        component: () => import( '@/components/WorkerList.vue')
        //component: Login
      },
      {
        path: 'apartment/new',
        name: 'apartment/new',
        props: true,
        component: () => import( '@/components/AddApartment.vue')
      },
      {
        path: 'apartment/:id',
        name: 'apartment',
        props: true,
        component: () => import( '@/components/AddApartment.vue')
      },
      {
        path: '/create-apartment',
        name: '/create-apartment',
        component: () => import( '@/components/AddApartment.vue')
        //component: Login
      },
    ]
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import( '../views/Login.vue')
    //component: Login
  },

  {
    path: '/register',
    name: 'Register',
    component: () => import( '../views/Register.vue')
    //component: Login
  },
  {
    path: '/forgot/:user',
    name: 'forgot',
    component: () => import( '../views/ForgotPassword.vue')
    //component: Login
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import( '../views/NewPassword.vue')
    //component: Login
  },

  // {
  //   path: '/tickets',
  //   name: 'Tickets',
  //   // component: () => import('../layouts/DefaultLayout.vue'),
  //   component: () => import('@/components/TicketsList.vue'),
  //   meta: {
  //     requiresAuth: true
  //   },
  //   children : [
  //   {
  //   path: 'create',
  //
  //   component: () => import( '@/components/AddTicket.vue'),
  //   },
  // ]
  // },
//   {
//     path: '/create',
//     name: 'Add Ticket',
//
//     component: () => import( '@/components/AddTicket.vue'),
//   meta: {
//   requiresAuth: true
// }
//   }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    // if (!store.getters.isLoggedIn) {
    if (!authService.isLoggedIn()) {
      if (to.name === 'register')
      { next ({ name: 'Register' })}
      else next({ name: 'Login' })
    } else {
      next() // go to wherever I'm going
    }
  }
  else {
    next();
  }
  // if (to.name === 'home' && !authService.isLoggedIn()) {
  //   next({name: 'login'})
  // } else if (authService.isLoggedIn() && to.name !== 'home'){
  //   next({name: 'tickets'})
  // } else {
  //   next();
  // }
});

export default router
