import {ActionContext, ActionTree, Store} from 'vuex'
import {Mutations, MutationType} from './mutations'
import {state, State} from './state'
import ticketService from "@/services/tickets.service";
import registersService from "@/services/registers.service";
import {RequestTickets} from "@/models/requestTickets";
import authService from "@/services/auth.service";
import Apartment from "@/models/apartment";
import TicketForm from "@/models/ticket-form";
import {useStore} from "@/store/index";
import websocketService from "@/services/websocket.service";
import {Message} from "@/models/chat/message";
import chatService from "@/services/chatService";
import {Owner} from "@/models/owner";
import {Worker} from "@/models/worker";
import ownerService from "@/services/ownerService";
import workerService from "@/services/workerService";
import locationService from "@/services/locations.service";

const store = useStore();

export enum ActionTypes {
    fetchTickets = "Fetch_Tickets",
    addTicket = "Add_Ticket",
    saveTicket = "Save_Ticket",
    startTicket = "Start_Ticket",
    deleteTicket = "Delete_Ticket",
    pauseTicket = "Pause_Ticket",
    finishTicket = "Finish_Ticket",
    closeTicket = "Close_Ticket",
    reopenTicket = "Reopen_Ticket",
    moveUp = "Move_Up",
    moveDown = "Move_Down",
    getDepartments = "GET_DEPARTMENTS",
    getStatuses = "GET_TICKET_STATUSES",
    getTeams = "GET_TEAMS",
    fetchTicketsOnDay ="Fetch_Tickets_ON_DAY",
    fetchTicketsOnDateRange ="Fetch_Tickets_ON_DATE_RANGE",
    fetchTicketsForQuery ="Fetch_Tickets_For_Query",
    getTickets ="Fetch_Tickets_ON_DAY_STATUS_USER",
    getUser="GET_USER",
    unSetUser="UNSET_USER",
    setUserLoggedIn="SET_USER_LOGGED_IN",
    fetchWorkersLoad="GET_WORKER_LOAD",
    fetchResources="GET_RESOURCES",
    fetchPriorities="GET_PRIORITY",
    fetchApartments="GET_APARTMENT",
    fetchTopics="GET_TOPICS",
    fetchTeams = "FETCH_TEAMS",
    addObject="Add_Object",
    updateObject="Update_Object",
    getObject = "GET_OBJECT",
    assignWorker= "ASSIGN_WORKER",
    removeWorker= "Remove_WORKER",
    assignMajor= "ASSIGN_MAJOR",
    removeMajor= "Remove_MAJOR",
    getTicket="GET_TICKET",
    getUserLocation="GET_USER_LOCATION",
    setConnection="SET_WEB_SOCKET_CONNECTION",
    changeConnectionStatus = "Change_WEB_SOCKET_CONNECTION",
    fetchChats = "FETCH_CHATS",
    fetchMessages = "FETCH_MESSAGES",
    getMessage = "GET_MESSAGE",
    sendMessage = "SEND_MESSAGE",
    deleteMessage = "DELETE_MESSAGE",

    fetchOwners = "FETCH_OWNERS",
    getOwner = "GET_OWNER",
    createOwner = "CREATE_OWNER",
    updateOwner = "UPDATE_OWNER",
    deleteOwner = "DELETE_OWNER",

    getWorkers = "FETCH_WORKERS",
    getWorker = "GET_WORKER",
    createWorker = "CREATE_WORKER",
    updateWorker = "UPDATE_WORKER",
    deleteWorker = "DELETE_WORKER",

    // fetchEmploees = "FETCH_OWNERS",
    // getOwner = "GET_OWNER",
    // createOwner = "CREATE_OWNER",
    // updateOwner = "UPDATE_OWNER",
    // deleteOwner = "DELETE_OWNER",

}

type ActionAugments = Omit<ActionContext<State, State>, 'commit'> & {
    commit<K extends keyof Mutations>(
        key: K,
        payload: Parameters<Mutations[K]>[1]
    ): ReturnType<Mutations[K]>;
}

export type Actions = {
    // [ActionTypes.fetchTickets]({commit}: ActionAugments, day: number | undefined, ticketStatus: string | undefined, userId: string | null): Promise<void>;
    [ActionTypes.fetchTickets]({commit}: ActionAugments): Promise<void>;
    [ActionTypes.fetchWorkersLoad]({commit}: ActionAugments,day: number): Promise<void>;
    [ActionTypes.fetchPriorities]({commit}: ActionAugments): Promise<void>;
    [ActionTypes.fetchApartments]({commit}: ActionAugments, office: number|null): Promise<void>;
    [ActionTypes.fetchTopics]({commit}: ActionAugments): Promise<void>;
    [ActionTypes.fetchTeams]({commit}: ActionAugments): Promise<void>;
    [ActionTypes.fetchTicketsOnDay]({commit}: ActionAugments, day: number): Promise<void>;
    [ActionTypes.fetchTicketsOnDateRange]({commit}: ActionAugments, dateRange: string): Promise<void>;
    [ActionTypes.fetchTicketsForQuery]({commit}: ActionAugments, query: RequestTickets): Promise<void>;
    [ActionTypes.getTickets]({commit}: ActionAugments,  request: RequestTickets): Promise<void>;
    [ActionTypes.addTicket]({commit}: ActionAugments, form: TicketForm): Promise<void>;
    [ActionTypes.addObject]({commit}: ActionAugments, apartment: Apartment): Promise<void>;
    [ActionTypes.updateObject]({commit}: ActionAugments, apartment: Apartment): Promise<void>;
    [ActionTypes.getObject]({commit}: ActionAugments, apartment: Apartment): Promise<void>;
    [ActionTypes.saveTicket]({commit}: ActionAugments, form: TicketForm): Promise<void>;
    [ActionTypes.getTicket]({commit}: ActionAugments, id: number): Promise<void>;
    [ActionTypes.getUserLocation]({commit}: ActionAugments, id: number): Promise<void>;
    [ActionTypes.startTicket]({commit}: ActionAugments, id: number): Promise<void>;
    [ActionTypes.deleteTicket]({commit}: ActionAugments, id: number): Promise<void>;
    [ActionTypes.pauseTicket]({commit}: ActionAugments, id: number): Promise<void>;
    [ActionTypes.finishTicket]({commit}: ActionAugments, id: number): Promise<void>;
    [ActionTypes.closeTicket]({commit}: ActionAugments, id: number): Promise<void>;
    [ActionTypes.reopenTicket]({commit}: ActionAugments, id: number): Promise<void>;
    [ActionTypes.moveUp]({commit}: ActionAugments,  request: RequestTickets): Promise<void>;
    [ActionTypes.moveDown]({commit}: ActionAugments, request: RequestTickets): Promise<void>;
    [ActionTypes.getDepartments]({commit}: ActionAugments): Promise<void>;
    [ActionTypes.getStatuses]({commit}: ActionAugments): Promise<void>;
    [ActionTypes.getTeams]({commit}: ActionAugments,  id: number): Promise<void>;
    [ActionTypes.getUser]({commit}: ActionAugments): Promise<void>;
    [ActionTypes.setUserLoggedIn]({commit}: ActionAugments): void;
    [ActionTypes.unSetUser]({commit}: ActionAugments): void;
    [ActionTypes.assignWorker]({commit}: ActionAugments,payload: {id: number; ticketId: number}): void;
    [ActionTypes.removeWorker]({commit}: ActionAugments,payload: {id: number; ticketId: number}): void;
    [ActionTypes.assignMajor]({commit}: ActionAugments,payload: {id: number; ticketId: number}): void;
    [ActionTypes.removeMajor]({commit}: ActionAugments,payload: {id: number; ticketId: number}): void;
    [ActionTypes.setConnection]({commit}: ActionAugments, connection: WebSocket): void;
    [ActionTypes.changeConnectionStatus]({commit}: ActionAugments, connection: WebSocket): void;
    [ActionTypes.fetchChats]({commit}: ActionAugments, userId: number): void;
    [ActionTypes.fetchMessages]({commit}: ActionAugments, chatId: number): void;
    [ActionTypes.getMessage]({commit}: ActionAugments, id: number): void;
    [ActionTypes.sendMessage]({commit}: ActionAugments, message: Message): void;
    [ActionTypes.deleteMessage]({commit}: ActionAugments, message: Message): void;
    [ActionTypes.fetchOwners]({commit}: ActionAugments, id: number): void;
    [ActionTypes.getOwner]({commit}: ActionAugments, id: number): void;
    [ActionTypes.createOwner]({commit}: ActionAugments, owner: Owner): void;
    [ActionTypes.updateOwner]({commit}: ActionAugments, owner: Owner): void;
    [ActionTypes.deleteOwner]({commit}: ActionAugments, owner: Owner): void;

    [ActionTypes.getWorkers]({commit}: ActionAugments, id: number): void;
    [ActionTypes.getWorker]({commit}: ActionAugments, id: number): void;
    [ActionTypes.createWorker]({commit}: ActionAugments, worker: Worker): void;
    [ActionTypes.updateWorker]({commit}: ActionAugments, worker: Worker): void;
    [ActionTypes.deleteWorker]({commit}: ActionAugments, worker: Worker): void;

}

export const actions: ActionTree<State, State> & Actions = {
    async [ActionTypes.fetchTickets]({commit}) {
       const day = Math.floor(Date.now() / 1000);
        const ticketStatus = "All";
        const userId = "374";
        commit(MutationType.setLoading, true)
        const {data} = await ticketService.get(day, ticketStatus, userId);
        commit(MutationType.fetchTickets, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchWorkersLoad]({commit},day: number) {
        commit(MutationType.setLoading, true)
        const {data} = await ticketService.getWorkersLoading( day);
        commit(MutationType.fetchWorkersLoad, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchApartments]({commit},office: number|null) {
        commit(MutationType.setLoading, true)
        const {data} = await registersService.apartments( office);
        commit(MutationType.fetchApartments, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchTopics]({commit}) {
        commit(MutationType.setLoading, true)
        const {data} = await registersService.topics();
        commit(MutationType.fetchTopics, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchTeams]({commit}) {
        commit(MutationType.setLoading, true)
        const {data} = await registersService.allTeams();
        commit(MutationType.fetchTeams, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchPriorities]({commit}) {
        commit(MutationType.setLoading, true)
        const {data} = await registersService.priorities();
        commit(MutationType.fetchPriorities, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchResources]({commit},day: number) {
        commit(MutationType.setLoading, true)
        const {data} = await ticketService.getWorkersResources( day);
        commit(MutationType.fetchResources, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchTicketsOnDay]({commit}: ActionAugments, day: number) {
        const dayInSeconds = (day>0) ? day : ((new Date()).getTime()/1000).toFixed(0);
        const ticketStatus = "All";
        const userId =  localStorage.getItem('USER_ID');
        commit(MutationType.setLoading, true)
        const {data} = await ticketService.get(dayInSeconds, ticketStatus, userId);
        console.log(data)
        commit(MutationType.fetchTickets, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchTicketsOnDateRange]({commit}: ActionAugments, dateRange: string) {
        const userId = localStorage.getItem('USER_ID')
        commit(MutationType.setLoading, true)
        const {data} = await ticketService.getForDateRange(dateRange,  Number(userId));
        console.log(data)
        commit(MutationType.fetchTickets, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.fetchTicketsForQuery]({commit}: ActionAugments, query: RequestTickets) {
        const userId = localStorage.getItem('USER_ID')
        commit(MutationType.setLoading, true)
        const {data} = await ticketService.getWithQuery(query);
        console.log(data)
        commit(MutationType.fetchTickets, data);
        commit(MutationType.setLoading, false)
    },
     async [ActionTypes.getTickets]({commit}: ActionAugments, request: RequestTickets) {
        const dayInSeconds = (request.day && request.day>0)?request.day : ((new Date()).getTime()/1000).toFixed(0);
        commit(MutationType.setLoading, true)
        const {data} = await ticketService.get(dayInSeconds, request.status, request.user);
        commit(MutationType.fetchTickets, data);
        commit(MutationType.setLoading, false)
    },
    async [ActionTypes.addTicket]({commit}, form: TicketForm) {
        console.log(form);
        const {data} = await ticketService.create(form);
        if (data.success) {
            commit(MutationType.setLoading, true)
            commit(MutationType.addTicket, data);
        }
        commit(MutationType.setLoading, false)

    },
    async [ActionTypes.addObject]({commit}, apartment: Apartment) {
        console.log(apartment);
        const {data} = await ticketService.newObject(apartment);
        if (data.success) {
            commit(MutationType.setLoading, true)
            apartment.id = data.id;
            commit(MutationType.addObject, apartment);
            commit(MutationType.setLoading, false)
        }
    },
    async [ActionTypes.updateObject]({commit}, apartment: Apartment) {
        console.log(apartment);
        const {data} = await ticketService.updateObject(apartment);
        if (data.success) {
            commit(MutationType.setLoading, true)
            apartment.id = data.id;
            commit(MutationType.updateObject, apartment);
            commit(MutationType.setLoading, false)
        }
    },
    async [ActionTypes.getObject]({commit}, apartment: Apartment) {
        console.log(apartment);
        const {data} = await ticketService.getObject(apartment.id);
        if (data.success) {
            commit(MutationType.setLoading, true)
            apartment.id = data.id;
            commit(MutationType.addObject, data);
            commit(MutationType.setLoading, false)
        }
    },
    async [ActionTypes.saveTicket]({commit}, form: TicketForm) {
        debugger
        const {data} = await ticketService.update(form);
        commit(MutationType.saveTicket, data);
    },
    async [ActionTypes.getUser]({commit}) {
        const user = await authService.getUser();
        commit(MutationType.getUser, user);
    },
    async [ActionTypes.setUserLoggedIn]({commit}) {
        const user = await authService.getUser();

        commit(MutationType.setUserLoggedIn, true);
        commit(MutationType.getUser, user);
    },
    async [ActionTypes.startTicket]({commit}, id: number) {
        const {data} = await ticketService.start(id);
        if (data.success) {
            commit(MutationType.startTicket, id);
        }
    },
    async [ActionTypes.pauseTicket]({commit}, id: number) {
        const {data} = await ticketService.pause(id);
        if (data.success) {
            commit(MutationType.pauseTicket, id);
        }
    },
    async [ActionTypes.finishTicket]({commit}, id: number) {
        const {data} = await ticketService.finish(id);
        if (data.success) {
            commit(MutationType.finishTicket, id);
        }
    },
    async [ActionTypes.closeTicket]({commit}, id: number) {
        const {data} = await ticketService.close(id);
        if (data.success) {
            commit(MutationType.closeTicket, id);
        }
    },
    async [ActionTypes.deleteTicket]({commit}, id: number) {
        const {data} = await ticketService.delete(id);
        if (data.success) {
            commit(MutationType.deleteTicket, id);
        }
    },
    async [ActionTypes.reopenTicket]({commit}, id: number) {
        const {data} = await ticketService.reopen(id);
        if (data.success) {
            commit(MutationType.reopenTicket, id);
        }
    },
    async [ActionTypes.getTicket]({commit}, id: number) {
        const {data} = await ticketService.ticket(id);
        if (data.success) {
            commit(MutationType.updateTicket, data);
        }
    },
    async [ActionTypes.getUserLocation]({commit}, id: number) {
        const {data} = await locationService.location(id);
        if (data.success) {
            commit(MutationType.updateLocation, data);
        }
    },
    async [ActionTypes.moveUp]({commit}, request: RequestTickets ) {
        if (request.id && request.day) {
            const {data} = await ticketService.moveUp(request.id, request.day);
            if (data.success) {
                commit(MutationType.moveUp, request.id);
            }
        }
    },
    async [ActionTypes.moveDown]({commit}, request: RequestTickets) {
        if (request.id && request.day) {
            const {data} = await ticketService.moveDown(request.id, request.day);
            if (data.success) {
                commit(MutationType.moveDown, request.id);
            }
        }
    },
    async [ActionTypes.getDepartments]({commit}) {
        const {data} = await registersService.departments();
        if (data) {
            console.log(data)
            commit(MutationType.getDepartments,data);
        }
    },
    async [ActionTypes.getStatuses]({commit}) {
        const {data} = await registersService.statuses();
        if (data) {
            console.log(data)
            commit(MutationType.getStatuses,data);
        }
    },
    async [ActionTypes.getTeams]({commit}, id: number) {
        const {data} = await registersService.teams(id);
        if (data) {
            console.log(data)
            commit(MutationType.getTeams,data);
        }
    },
    async [ActionTypes.assignWorker]({commit}, payload: {id: number; ticketId: number}) {

        const {data} = await ticketService.assignCoWorker(payload.id, payload.ticketId);
        if (data.success) {
            commit(MutationType.assignWorker, data.ticket);
            commit(MutationType.saveTicket, data.ticket);
        }
    },
    async [ActionTypes.assignMajor]({commit}, payload: {id: number; ticketId: number}) {
        const {data} = await ticketService.assignMajor(payload.id, payload.ticketId);
        if (data.success) {
            commit(MutationType.assignMajor, data.ticket);
            commit(MutationType.saveTicket, data.ticket);
        }
    },
    async [ActionTypes.removeWorker]({commit}, payload: {id: number; ticketId: number}) {

        const {data} = await ticketService.removeCoWorker(payload.id, payload.ticketId);
        if (data.success) {
            commit(MutationType.assignWorker, data.ticket);
            commit(MutationType.saveTicket, data.ticket);
        }
    },
    async [ActionTypes.removeMajor]({commit}, payload: {id: number; ticketId: number}) {
        const {data} = await ticketService.removeMajor(payload.id, payload.ticketId);
        if (data.success) {
            commit(MutationType.assignMajor, data.ticket);
            commit(MutationType.saveTicket, data.ticket);
        }
    },
    [ActionTypes.unSetUser]({commit}) {
        commit(MutationType.unSetUser,false);
    },
    [ActionTypes.setConnection]({commit}, connection: WebSocket) {
        commit(MutationType.setConnection, connection);
    },
    [ActionTypes.changeConnectionStatus]({commit}, connection: WebSocket) {
        commit(MutationType.changeConnectionStatus, connection);
    },

    async [ActionTypes.fetchChats]({commit}: ActionAugments, userId: number) {
        const {data} = await chatService.fetchChats(userId);
        if (data) {
            console.log(data)
            commit(MutationType.fetchChats, data.chats);
        }
    },
    async [ActionTypes.fetchMessages]({commit}: ActionAugments, chatId: number) {
        const {data} = await chatService.fetchMessages(chatId);
        if (data) {
            console.log(data)
            commit(MutationType.fetchMessages, data);
        }
    },
    // async [ActionTypes.setMessage]({commit}: ActionAugments, message: Message) {
    //     const {data} = await chatService.getMessage(message);
    //     if (data) {
    //         console.log(data)
    //         commit(MutationType.getMessage, data);
    //     }
    // },
    async [ActionTypes.getMessage]({commit}: ActionAugments, id: number) {

        const {data} = await chatService.getMessage(id);
        if (data) {
            console.log(data)
            commit(MutationType.getMessage, data);
        }
    },
    async [ActionTypes.sendMessage]({commit}: ActionAugments, message: Message) {
        const {data} = await chatService.sendMessage(message);
        if (data) {
            console.log(data)
            commit(MutationType.sendMessage, data);
        }
    },
    async [ActionTypes.deleteMessage]({commit}: ActionAugments, message: Message) {
        const {data} = await chatService.deleteMessage(message.id);
        if (data.success) {
            console.log(data)
            commit(MutationType.deleteMessage, message);
        }
    },
 async [ActionTypes.fetchOwners]({commit}: ActionAugments, id: number) {
        const {data} = await ownerService.fetchOwners(id);
        if (data) {
            console.log(data)
            commit(MutationType.fetchOwners, data);
        }
    },
    // async [ActionTypes.setOwner]({commit}: ActionAugments, message: Owner) {
    //     const {data} = await chatService.getOwner(message);
    //     if (data) {
    //         console.log(data)
    //         commit(MutationType.getOwner, data);
    //     }
    // },
    async [ActionTypes.getOwner]({commit}: ActionAugments, id: number) {

        const {data} = await ownerService.getOwner(id);
        if (data) {
            console.log(data)
            commit(MutationType.getOwner, data);
        }
    },
    async [ActionTypes.createOwner]({commit}: ActionAugments, owner: Owner) {

        const {data} = await ownerService.createOwner(owner);
        if (data.success) {
            console.log(data)
            commit(MutationType.createOwner, data);
        }
    },
    async [ActionTypes.updateOwner]({commit}: ActionAugments, owner: Owner) {
        const {data} = await ownerService.updateOwner(owner);
        if (data) {
            console.log(data)
            commit(MutationType.updateOwner, data);
        }
    },
    async [ActionTypes.deleteOwner]({commit}: ActionAugments, owner: Owner) {
        const {data} = await ownerService.deleteOwner(owner.id);
        if (data.success) {
            console.log(data)
            commit(MutationType.deleteOwner, owner.id);
        }
    },
    async [ActionTypes.getWorkers]({commit}: ActionAugments, id: number) {
        const {data} = await workerService.getWorkers(id);
        if (data) {
            console.log(data)
            commit(MutationType.fetchWorkersLoad, data);
        }
    },
    // async [ActionTypes.setWorker]({commit}: ActionAugments, message: Worker) {
    //     const {data} = await chatService.getWorker(message);
    //     if (data) {
    //         console.log(data)
    //         commit(MutationType.getWorker, data);
    //     }
    // },
    async [ActionTypes.getWorker]({commit}: ActionAugments, id: number) {

        const {data} = await workerService.getWorker(id);
        if (data) {
            console.log(data)
            commit(MutationType.getWorker, data);
        }
    },
    async [ActionTypes.createWorker]({commit}: ActionAugments, worker: Worker) {

        const {data} = await workerService.createWorker(worker);
        if (data.success) {
            console.log(data)
            commit(MutationType.createWorker, data);
        }
    },
    async [ActionTypes.updateWorker]({commit}: ActionAugments, worker: Worker) {
        const {data} = await workerService.updateWorker(worker);
        if (data) {
            console.log(data)
            commit(MutationType.updateWorker, data);
        }
    },
    async [ActionTypes.deleteWorker]({commit}: ActionAugments, worker: Worker) {
        const {data} = await workerService.deleteWorker(worker.id);
        if (data.success) {
            console.log(data)
            commit(MutationType.deleteWorker, worker.id);
        }
    }
}
